define(['jquery'], function($) {
  
  window.faqLoaded = true;

  if ($('.customer-service .queryProductLists input:checkbox').length) {
    var $productSelection = $('.customer-service .queryProductLists input:checkbox');
    enableDisableQueryCategories($productSelection);

    $productSelection.click(function() {
      enableDisableQueryCategories($productSelection);
    });
  }

  function enableDisableQueryCategories(productSelection) {
    var $queryCategories = $('.customer-service div.select-wrap select');
    var productsChosen = (productSelection.filter(':checked').length > 0);

    if (productsChosen === false) {
      $queryCategories.attr('value', '');
      $queryCategories.trigger('change');
    }

    $queryCategories.attr('disabled', !productsChosen);
  }

  $('.customer-service #CustomerQueryForm label[for="comment"], .customer-service #CustomerQueryForm #comment, .customer-service #CustomerQueryForm p.char-limit,.customer-service #CustomerQueryForm .trans-button, .customer-service #CustomerQueryForm #selectedFileUpload, .customer-service #CustomerQueryForm .instructions').hide();
  $('.customer-service #CustomerQueryForm select[name*="queryCategoryId"]').change(function() {
    var chosenOption = $(this).val();

    if (!chosenOption) {
      $('.customer-service #CustomerQueryForm label[for="comment"], .customer-service #CustomerQueryForm textarea#comment, .customer-service #CustomerQueryForm p, .customer-service #CustomerQueryForm button.send, .customer-service #CSFaqs, .customer-service .csfaq-fallback').hide();
    }
  });

  $('#CSFaqs .csfaq-head').live('click', function() {
    if ($(this).not(':animated')) {
      $(this).children('.csfaq-body').slideToggle();
    }
  });
  $('#CSFaqs .csfaq-head h3').live('hover', function() {
    $(this).css('cursor', 'pointer');
  }, function() {
    $(this).css('cursor', 'default');
  });
  $('.csfaq-fallback').live('click', function() {
    $(this).remove();
    $('.customer-service #CustomerQueryForm .formerror').fadeIn();
    $('.customer-service #CustomerQueryForm label[for="comment"], .customer-service #CustomerQueryForm textarea#comment, .customer-service #CustomerQueryForm p, .customer-service #CustomerQueryForm button.send').show();
    $('.customer-service #CustomerQueryForm .instructions').insertAfter('.customer-service #CustomerQueryForm label[for="comment"]');
  });
});
